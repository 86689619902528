<template>
  <div class="q-px-xl q-py-lg" data-cy="product-model-page-content">
    <h3 class="q-mt-sm q-mb-sm"> Select Product Model </h3>
    <p> Please select the product model you want to work with in the Variant Management: </p>

    <div class="row q-pt-md w">
      <section class="col">
        <div class="list">
          <template v-if="areProductModelsLoading">
            <product-model v-for="skeleton in skeletonProductModels" :key="skeleton">
              <template #header>
                <mi-skeleton type="radio"></mi-skeleton>
                <mi-skeleton class="q-ml-md" type="text" width="100px"></mi-skeleton>

                <mi-skeleton class="q-ml-auto" type="circle" width="32px" height="32px"></mi-skeleton>
                <mi-skeleton class="q-ml-md" type="btn" height="30px" width="113px"></mi-skeleton>
                <mi-skeleton class="q-ml-md" type="btn" height="30px" width="175px"></mi-skeleton>
              </template>
            </product-model>
          </template>

          <!-- Product models list -->
          <template v-else-if="productModels.length">
            <div class="list__content">
              <product-model
                v-for="productModel in productModels"
                :key="productModel.id"
                :to="{ name: PRODUCT_MODEL_ROUTE.name, params:
                  { encodedBusinessName: productModel.encodedBusinessName } }"
                :product-model="productModel"
                group="productModels"
              ></product-model>
            </div>
          </template>

          <!-- Empty state -->
          <div v-else class="list__empty flex flex-center">
            <mi-icon name="warning-circle" size="1.75rem"></mi-icon>
            <h6 class="q-my-none q-ml-sm"> No product models found </h6>
          </div>
        </div>

        <template v-if="productModels.length">
          <product-model-auto-bom></product-model-auto-bom>
        </template>
      </section>

      <!-- Product model details -->
      <section class="product-model-details-wrapper col-xl-3 col-4 q-ml-xl">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { PRODUCT_MODEL_ROUTE } from '@/router/routeNames'

  import ProductModelAutoBom from '@/components/product-model/ProductModelAutoBom.vue'
  import ProductModel from '@/components/product-model/ProductModel.vue'

  const { mapState, mapActions } = createNamespacedHelpers('product-model')

  export default {
    name: 'ProductModels',
    components: { ProductModel, ProductModelAutoBom },
    data: () => ({
      PRODUCT_MODEL_ROUTE,
      skeletonProductModels: 5
    }),
    computed: {
      ...mapState(['productModels', 'areProductModelsLoading'])
    },
    async created() {
      await this.getProductModelsList()
    },
    methods: {
      ...mapActions(['getProductModelsList'])
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-details-wrapper {
    max-width: 450px;
  }

  .list {
    height: 85%;

    &__content {
      height: 100%;
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: $mi-silver-100;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &__empty {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  section .col {
    height: 100%;
  }
</style>
