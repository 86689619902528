<template>
  <!-- Radio -->
  <mi-radio
    :label="productModel.businessName"
    :model-value="selectedProductModelEncodedBusinessName"
    :val="productModel.encodedBusinessName"
    class="no-pointer-events"
    color="accent"
  ></mi-radio>

  <div v-show="addedNewValidation()" class="product-model-item__new">
    <span>New</span>
  </div>

  <div class="flex items-center q-ml-auto">
    <!-- Settings button -->
    <mi-btn
      :loading="areSettingsLoading"
      class="product-model-item__setting-btn"
      icon="settings"
      icon-size="16px"
      icon-type
      flat
      fab
      @click.stop.prevent="openValidationSettingsDialog"
    ></mi-btn>

    <!-- Auto BOM & Not usable choices buttons -->
    <mi-btn
      v-for="validationType in validationTypes"
      :key="validationType.key"
      :loading="isValidationLoading && validationType.key === selectedValidationType"
      class="product-model-item__expand-btn"
      :color="validationType.key === selectedValidationType && !isValidationLoading ? 'accent' : 'secondary'"
      dense
      @click.stop.prevent="emitExpandItem(validationType.key)"
    >
      {{ validationType.name }}
    </mi-btn>
  </div>

  <!-- Settings dialog -->
  <product-model-settings
    v-model="isSettingsDialogShown"
    :validation-settings="validationSettings"
  >
  </product-model-settings>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { getValidationSettings } from '@/api'
  import { PRODUCT_MODEL_ROUTE } from '@/router/routeNames'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { PROD_MODEL_SETTINGS_OPENED } from '@/utils/analytics/constants'
  import ProductModelSettings from './settings/ProductModelSettings.vue'

  const { mapState } = createNamespacedHelpers('product-model')

  export default {
    name: 'ProductModelHeader',
    components: { ProductModelSettings },
    props: {
      isValidationLoading: {
        type: Boolean,
        required: false,
        default: false
      },
      productModel: {
        type: Object,
        required: true
      },
      selectedValidationType: {
        type: String,
        required: false,
        default: ''
      },
      validationTypes: {
        type: Array,
        required: true
      }
    },
    emits: ['click:expand'],
    data: () => ({
      isSettingsDialogShown: false,
      areSettingsLoading: false,
      viewQueryParam: 'settings',
      validationSettings: {
        bom: { label: 'BOM', items: [] },
        notUsableChoices: { label: 'Not usable choices', items: [] }
      }
    }),
    computed: {
      ...mapState(['selectedProductModelEncodedBusinessName'])
    },
    watch: {
      isSettingsDialogShown(current) {
        if (!current) {
          const { view, ...query } = this.$route.query

          this.replaceCurrentRoute(query)
        }
      }
    },
    created() {
      const { params, query } = this.$route

      if (params?.encodedBusinessName === this.productModel.encodedBusinessName
        && query?.view === this.viewQueryParam) {
        this.openValidationSettingsDialog()
      }
    },
    methods: {
      addedNewValidation() {
        const creationDate = new Date(this.productModel.creationDate ?? '')

        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setHours(19, 0, 0, 0)

        return creationDate > yesterday
      },
      async openValidationSettingsDialog() {
        this.areSettingsLoading = true

        try {
          const {
            bomSettings,
            invalidChoicesSettings
          } = await getValidationSettings(this.productModel.encodedBusinessName)

          this.validationSettings.bom.items = bomSettings
          this.validationSettings.notUsableChoices.items = invalidChoicesSettings

          this.setViewParamInRouteQuery()
          this.isSettingsDialogShown = true

          // Analytics
          recordAnalytics(
            PROD_MODEL_SETTINGS_OPENED,
            { productModelBusinessName: this.productModel.businessName }
          )
        }
        finally {
          this.areSettingsLoading = false
        }
      },
      setViewParamInRouteQuery() {
        const query = {
          ...(this.productModel.encodedBusinessName === this.selectedProductModelEncodedBusinessName
            && this.$route.query),
          view: this.viewQueryParam
        }

        this.replaceCurrentRoute(query)
      },
      emitExpandItem(selectedType = '') {
        const query = this.selectedValidationType === selectedType ? {} : { selectedType }

        this.replaceCurrentRoute(query)
        this.$emit('click:expand', selectedType)
      },
      replaceCurrentRoute(query = {}) {
        this.$router.replace({
          query,
          name: PRODUCT_MODEL_ROUTE.name,
          params: { encodedBusinessName: this.productModel.encodedBusinessName }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-item__new {
    display: flex;
    height: 24px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-left: 10px;
    border-radius: 24px;
    background: $mi-green-100;

    span {
      color: $mi-green-900;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
  }

  .product-model-item__setting-btn {
    font-size: .8rem;
  }

  .product-model-item__expand-btn.q-btn--dense {
    font-size: 1rem;
    margin-left: .75rem;
  }
</style>
