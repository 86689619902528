<template>
  <mi-list class="product-model-item-content q-px-sm" separator>
    <template v-if="validations.length">
      <mi-list-item v-for="validation in validations" :key="validation.id" class="q-px-sm">
        <!-- Name -->
        <mi-list-item-section avatar>
          {{ validation.name }}
        </mi-list-item-section>

        <!-- Planning period range -->
        <mi-list-item-section class="items-center">
          <strong class="q-mr-xs"> PP: </strong>
          {{ validation.planningPeriodRange.from }} - {{ validation.planningPeriodRange.to }}
        </mi-list-item-section>

        <!-- Download button -->
        <mi-btn
          :loading="excelLoadings.get(validation.id)"
          class="q-px-sm"
          icon="cloud-download"
          icon-type
          icon-size="22px"
          dense
          flat
          @click="exportExcel(validation)"
        >
          Download
        </mi-btn>
      </mi-list-item>
    </template>

    <mi-list-item v-else class="items-center q-px-sm"> No planning periods available </mi-list-item>
  </mi-list>
</template>

<script>
  import { exportFile } from 'quasar'
  import { exportBOMExcel, exportInvalidChoicesExcel } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PROD_MODEL_EXPORTED_EXCEL } from '@/utils/analytics/constants'

  export default {
    name: 'ProductModelContent',
    props: {
      filenamePrefix: {
        type: String,
        required: false,
        default: ''
      },
      productModel: {
        type: Object,
        required: true
      },
      selectedValidationType: {
        type: String,
        required: true
      },
      validations: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      validationIdToLoadExcel: '',
      excelLoadings: new Map()
    }),
    watch: {
      validations(current) {
        if (current.length) {
          this.fillInExcelLoadings()
        }
      }
    },
    methods: {
      async exportExcel(validation = {}) {
        const { businessName, creationDate } = this.productModel
        const exportExcelFn = this.selectedValidationType === 'bomValidations'
          ? exportBOMExcel
          : exportInvalidChoicesExcel

        this.excelLoadings.set(validation.id, true)

        try {
          const excelFile = await exportExcelFn(validation.id)
          const filename = `${ this.filenamePrefix }_${ validation.name }_${ businessName }_${ creationDate }.xlsx`

          exportFile(filename, new Blob([excelFile]))

          // Analytics
          recordAnalytics(
            PROD_MODEL_EXPORTED_EXCEL,
            {
              productModelBusinessName: businessName,
              exportedAutoBom: validation.name
            }
          )
        }
        finally {
          this.excelLoadings.set(validation.id, false)
        }
      },
      fillInExcelLoadings() {
        this.validations.forEach(validation => this.excelLoadings.set(validation.id, false))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-item-content {
    border-top-width: $mi-expansion-item-outlined-border-width;
    border-top-style: solid;
    border-top-color: inherit;

    .q-item__section--avatar {
      flex: 1 1 30%;
      min-width: 185px;
    }

    .q-item__section--main {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
</style>
