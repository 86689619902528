<template>
  <div class="product-model-auto-bom">
    <h1>Auto Bom</h1>
    <div v-if="productModelsAutoBom?.loading" class="wrapper row">
      <div className="text col skeleton">
        <mi-skeleton class="q-ml-md" type="text" width="150px"></mi-skeleton>
        <mi-skeleton class="q-ml-md" type="text" width="90px"></mi-skeleton>
      </div>
      <div className="button col">
        <mi-skeleton class="q-ml-md" type="btn" height="32px" width="133px"></mi-skeleton>
      </div>
    </div>
    <div v-else class="wrapper row">
      <div class="text col">
        <span class="info">
          {{ productModelsAutoBom?.counts.setupCount }} setup |
          {{ productModelsAutoBom?.counts.completedCount }} complete
        </span>
        <span class="updated">
          Last update in {{ productModelsAutoBom?.lastUpdate }}
        </span>
      </div>
      <div class="button col">
        <mi-btn
          class="mi-btn"
          dense
          :disabled="productModelsAutoBom?.counts?.completedCount === 0"
          color="secondary"
          @click="downloadCompleteInfo"
        >
          Download complete
        </mi-btn>
      </div>
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line import/named
  import { downloadCompleteAutoBom } from '@/api'
  import { createNamespacedHelpers } from 'vuex'

  const { mapState, mapActions, mapGetters } = createNamespacedHelpers('product-model')

  export default {
    name: 'ProductModelAutoBom',
    computed: {
      ...mapState(['productModelsAutoBom', 'productModels']),
      ...mapActions(['getProductModelsCount']),
      ...mapGetters(['queryProductModels'])
    },
    async created() {
      await this.getProductModelsCount
    },
    methods: {
      async downloadCompleteInfo() {
        const { id } = this.queryProductModels
        const file = await downloadCompleteAutoBom(id)
        this.downloadFile(file, this.zipFilename())
      },
      downloadFile(data, fileName) {
        const blob = new Blob([data])
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      },
      zipFilename() {
        const date = new Date().toUTCString().slice(5, 16).replaceAll(' ', '_')

        return `auto_boms_${ date }.zip`
      }
    }
  }

</script>

<style lang="scss" scoped>
  .product-model-auto-bom {
    h1 {
      color: $mi-anthracite-800;
      font-family: $mi-typography-font-family-condensed;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }

    .wrapper {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      background: #ffffff;
      border-color: $mi-expansion-item-outlined-border-color;
      border-width: $mi-expansion-item-outlined-border-width;
      border-style: solid;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: $mi-typography-font-family;

      .info {
        color: $mi-anthracite-800;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }

      .updated {
        color: $mi-anthracite-500;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }

    .button {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .mi-btn {
        display: flex;
        height: 32px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px; /* 121.429% */
      }
    }
  }
</style>
