<template>
  <mi-tab-panel :name="name" class="q-px-none validations-scroll">
    <!-- Titles -->
    <template v-if="settings.length">
      <div class="row q-gutter-x-lg q-pb-sm header">
        <div class="col-4 header__vn">
          <span class="text-body3 text-weight-bold"> Validation name </span>
        </div>
        <div class="col header__pp">
          <span class="text-body3 text-weight-bold"> Planning period </span>
        </div>
      </div>

      <!-- Setting items -->
      <div
        v-for="(settingItem, index) in settings"
        :key="index"
        class="validation-settings-item row items-start q-gutter-x-lg"
      >
        <!-- Name -->
        <div class="col-4 validation-row-center">
          <mi-text-field
            v-model.trim="settingItem.name"
            :disable="!shouldDisplay"
            :name="name"
            :rules="nameRules"
            placeholder="Name"
            outlined
          ></mi-text-field>
        </div>

        <!-- Planning period range -->
        <planning-period-range
          v-model:from="settingItem.checkRange.from"
          v-model:to="settingItem.checkRange.from"
          :field-name="name"
          :disable="!shouldDisplay"
          :disable-to="true"
          label-from=" "
          label-to=" "
          class="col no-wrap"
        ></planning-period-range>

        <!-- Remove button -->
        <mi-btn
          v-show="shouldDisplay"
          class="q-ml-xs q-mt-xs q-pa-none validation-row-center"
          color="negative"
          icon="minus-circle"
          icon-type
          fab
          flat
          @click="$emit('click:remove', index)"
        ></mi-btn>
      </div>
    </template>

    <div v-else class="text-center q-py-sm"> No planning periods specified </div>
  </mi-tab-panel>
</template>

<script>
  import { required } from '@/utils/validators'

  import PlanningPeriodRange from '@/components/base/PlanningPeriodRange.vue'

  export default {
    name: 'ProductModelSettingsTab',
    components: { PlanningPeriodRange },
    props: {
      name: {
        type: [String, Number],
        required: true
      },
      settings: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    emits: ['click:remove'],
    data: () => ({
      validationSettingsLimit: 10,
      nameRules: [required],
      notUsableChoices: 'notUsableChoices',
      notUsableChoicesPrivileges: 'CREATE_NOT_USABLE_CHOICES_SETTINGS',
      bomPrivileges: 'CREATE_VALIDATION_SETTINGS',
      shouldDisplay: true
    })
  }
</script>

<style lang="scss" scoped>
  .validations-scroll {
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .validation-settings-item {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    .validation-row-center {
      margin-top: 0;
    }

    ::v-deep(.mi-btn) {
      opacity: 0;
      pointer-events: none;
      font-size: .85rem;
    }

    &:hover ::v-deep(.mi-btn) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .planning-period-range {
    gap: 8px;
    margin-top: 0;
    margin-left: 8px;
  }

  ::v-deep(.q-field) {
    // stylelint-disable declaration-no-important
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .header {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .2px;
    color: var(--main-colors-anthracite-anthracite-800-primary);

    &__pp {
      margin-left: 8px;
    }
  }

  ::v-deep(.q-field__bottom) {
    padding: 0 2px !important;
  }
</style>
