<template>
  <mi-expansion-item
    v-model="isProductModelExpanded"
    :class="{ 'product-model-item--selected':
      productModel.encodedBusinessName === selectedProductModelEncodedBusinessName }"
    class="product-model-item"
    expand-icon-toggle
    no-expand-icon
    outlined
    popup
    @after-hide="selectedValidationType = ''"
  >
    <!-- Header -->
    <template #header>
      <div class="full-width flex items-center q-py-sm q-pr-md q-pl-lg cursor-pointer">
        <slot name="header">
          <product-model-header
            :is-validation-loading="validation.isLoading"
            :product-model="productModel"
            :selected-validation-type="selectedValidationType"
            :validation-types="validationTypes"
            @click:expand="toggleExpansionItem"
          ></product-model-header>
        </slot>
      </div>
    </template>

    <!-- Content -->
    <product-model-content
      :filename-prefix="currentExcelFilenamePrefix"
      :product-model="productModel"
      :selected-validation-type="selectedValidationType"
      :validations="currentValidation"
    ></product-model-content>
  </mi-expansion-item>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { getProductModelBOMValidation } from '@/api'

  import { PROD_MODEL_VALIDATIONS } from '@/utils/analytics/constants'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import ProductModelContent from './ProductModelContent.vue'
  import ProductModelHeader from './ProductModelHeader.vue'

  const { mapState } = createNamespacedHelpers('product-model')

  export default {
    name: 'ProductModel',
    components: {
      ProductModelHeader,
      ProductModelContent
    },
    props: {
      productModel: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data: () => ({
      isProductModelExpanded: false,
      selectedValidationType: '',
      validation: {
        isCached: false,
        isLoading: false,
        bomValidations: [],
        notUsableChoices: []
      },
      validationTypes: [
        { key: 'bomValidations', name: 'Auto BOM', filenamePrefix: 'BOM' },
        { key: 'notUsableChoices', name: 'Not usable choices', filenamePrefix: 'Not_usable_choices' }
      ]
    }),
    computed: {
      ...mapState(['selectedProductModelEncodedBusinessName']),

      currentValidation() {
        return this.validation[this.selectedValidationType] || []
      },
      currentExcelFilenamePrefix() {
        return this.validationTypes.find(
          validationType => validationType.key === this.selectedValidationType
        )?.filenamePrefix || ''
      }
    },
    created() {
      const { params, query } = this.$route
      const validationTypeNames = this.validationTypes.map(type => type.key)

      if (params?.encodedBusinessName === this.productModel.encodedBusinessName
        && validationTypeNames.includes(query?.selectedType)) {
        this.toggleExpansionItem(query.selectedType)
      }
    },
    methods: {
      async fetchValidation() {
        this.validation.isLoading = true

        try {
          const { bomValidations, invalidChoices } = await getProductModelBOMValidation(this.productModel.id)

          this.validation.bomValidations = bomValidations.filter(validation => validation.status === 'PROCESSED')
          this.validation.notUsableChoices = invalidChoices.filter(choice => choice.status === 'PROCESSED')

          this.isProductModelExpanded = true
          this.validation.isCached = true
        }
        finally {
          this.validation.isLoading = false
        }
      },
      toggleExpansionItem(validationType = '') {
        if (validationType === this.selectedValidationType) {
          this.isProductModelExpanded = !this.isProductModelExpanded

          return
        }

        this.selectedValidationType = validationType

        if (this.validation.isCached) {
          this.isProductModelExpanded = true
        }
        else {
          this.fetchValidation()
        }

        this.dispatchRecordAnalytics(validationType)
      },
      dispatchRecordAnalytics(type) {
        recordAnalytics(
          PROD_MODEL_VALIDATIONS,
          {
            info: type,
            productModelBusinessName: this.productModel.businessName
          }
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-item {
    ::v-deep(.mi-expansion-item__header) {
      padding: 0;
      font-weight: 500;
    }

    &.q-expansion-item--collapsed + &--selected.q-expansion-item--collapsed ::v-deep(.q-expansion-item__container) {
      margin-top: -1 * $mi-expansion-item-outlined-border-width;
      border-top-width: $mi-expansion-item-outlined-border-width;
    }
  }

  .product-model-item--selected.mi-expansion-item--outlined > ::v-deep(.q-expansion-item__container) {
    border-color: $mi-red-500;
  }

  .product-model-item--selected ::v-deep(.mi-expansion-item__header) {
    background-color: $mi-red-50;
  }
</style>
