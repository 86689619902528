<template>
  <div>
    <mi-card v-if="!areProductModelsLoading && productModels.length" class="product-model-details column q-pa-xs">
      <template v-if="selectedProductModel.id">
        <!-- Title -->
        <mi-card-section>
          <h6 class="q-my-none text-uppercase"> Product Model details: </h6>
        </mi-card-section>

        <!-- Information (details) -->
        <mi-card-section>
          <p class="q-mb-sm">
            <strong class="text-family-condensed"> Name: </strong>
            {{ selectedProductModel.name }}
          </p>
          <p class="q-mb-sm">
            <strong class="text-family-condensed"> Business Name: </strong>
            {{ selectedProductModel.businessName }}
          </p>
          <p class="q-mb-sm">
            <strong class="text-family-condensed"> TAR: </strong>
            {{ selectedProductModel.tar ? selectedProductModel.tar : "-" }}
          </p>
          <p class="q-mb-sm">
            <strong class="text-family-condensed"> Combinatoric Rules: </strong>
            {{ selectedProductModel.kombination ? selectedProductModel.kombination : "-" }}
          </p>
          <p class="q-mb-sm">
            <strong class="text-family-condensed"> Creation Date: </strong>
            {{ selectedProductModel.creationDate }}
          </p>
          <p class="q-mb-none">
            <strong class="text-family-condensed"> Creator: </strong>
            {{ selectedProductModel.creator }}
          </p>
        </mi-card-section>

        <!-- Load button -->
        <mi-card-actions class="q-mt-auto" align="right">
          <mi-btn
            data-cy="load-product-models-btn"
            color="accent"
            @click="applySelectedProductModel"
          >
            Load Product Model
          </mi-btn>
        </mi-card-actions>
      </template>

      <!-- No product model selected caption -->
      <div v-else class="flex-center column q-my-auto">
        <mi-icon name="warning-circle" size="2rem"></mi-icon>
        <h6 class="q-mb-none q-mt-md"> No product model details found </h6>
      </div>
    </mi-card>
    <product-model-change-dialog
      v-if="isProductModelSelectedDialogShown"
      v-model="isProductModelSelectedDialogShown"
      :modified-elements="modifiedElements"
      :close-dialog="closeDialog"
      @update:product-model="updateProductModelWithDetectedChanges"
    >
    </product-model-change-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'

  import { detectElementsChangeFromProductModel, postSummary } from '@/api'
  import { SELECT_PRODUCT_MODEL, SELECT_SEARCH_PRODUCT_MODEL } from '@/store/modules/product-model/mutationTypes'
  import { SEARCH_ROUTE } from '@/router/routeNames'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PROD_MODEL_LOADED } from '@/utils/analytics/constants'
  import { PM_TYPE } from '@/constants'
  import ProductModelChangeDialog from '@/components/product-model/ProductModelChangeDialog.vue'
  import notify from '@/utils/notify'

  export default {
    name: 'ProductModelDetails',
    components: { ProductModelChangeDialog },
    data: () => ({
      isProductModelSelectedDialogShown: false,
      encodedBusinessName: '',
      productModelToBeChanged: {}
    }),
    computed: {
      ...mapState(['user']),
      ...mapGetters('product-model', ['selectedProductModel']),
      ...mapGetters('search', ['selectedOptions', 'selectedComponents']),
      ...mapState('product-model', ['areProductModelsLoading', 'productModels', 'selectedSearchProductModel'])
    },
    created() {
      this.$watch(
        () => this.$route.params.encodedBusinessName,
        encodedBusinessName => {
          if (encodedBusinessName) {
            this.SELECT_PRODUCT_MODEL(encodedBusinessName)
          }
        },
        { immediate: true }
      )
    },
    methods: {
      ...mapMutations('product-model', { SELECT_PRODUCT_MODEL, SELECT_SEARCH_PRODUCT_MODEL }),
      ...mapActions('search', ['updateListIfElementIdChange']),

      closeDialog() {
        this.isProductModelSelectedDialogShown = false
      },

      async applySelectedProductModel() {
        this.$q.loading.show({
          message: `Loading "${ this.selectedProductModel.businessName }" product model. Please wait ...`
        })

        try {
          await this.detectElementsChangeFromPM(this.selectedProductModel)
          recordAnalytics(PROD_MODEL_LOADED)
        }
        finally {
          this.$q.loading.hide()
        }
      },
      updateProductModelFromDialog() {
        this.changeProductModel(this.productModelToBeChanged)
      },
      changeProductModel({ id, encodedBusinessName, productModelType }) {
        const content = { id, encodedBusinessName, productModelType }

        this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL, content)
        this.$q.localStorage.remove(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL)

        this.SELECT_PRODUCT_MODEL(encodedBusinessName)
        this.SELECT_SEARCH_PRODUCT_MODEL({})

        this.$router.push({ name: SEARCH_ROUTE.name })
      },
      getOldProductModel() {
        const productModel = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL)
        const vmProductModel = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL)
        return productModel || vmProductModel || this.productModelToBeChanged
      },
      async detectElementsChange({ id, encodedBusinessName, productModelType }) {
        const oldPm = this.getOldProductModel()
        const params = {
          options: this.selectedOptions,
          components: this.selectedComponents,
          pmInfo: {
            pmType: productModelType,
            pmIdentifier: productModelType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id
          },
          oldPmInfo: {
            pmType: oldPm.productModelType,
            pmIdentifier: oldPm.productModelType === PM_TYPE.PRODUCT_MODEL ? oldPm.encodedBusinessName : oldPm.id
          }
        }

        try {
          return await detectElementsChangeFromProductModel(params)
        }
        catch (e) {
          notify({
            title: `HTTP Error ${ e.status || '' }`,
            content: 'Error when changing the Product Model',
            type: 'negative'
          })
          throw e
        }
      },
      async updateProductModelWithDetectedChanges({ detectElementsData, saveSummary = true }) {
        const { encodedBusinessName, productModelType } = this.productModelToBeChanged

        this.updateListIfElementIdChange({ detectElementsData })

        saveSummary && await postSummary({
          name: `${ this.user.username }_CurrentSelection`,
          pmEncodedBusinessName: encodedBusinessName,
          pmType: productModelType,
          options: this.selectedOptions,
          components: this.selectedComponents,
          currentSelection: true
        })

        this.changeProductModel(this.productModelToBeChanged)
      },
      hasElementsToDetectChanges() {
        return (this.selectedOptions && !!this.selectedOptions.length)
          || (this.selectedComponents && !!this.selectedComponents.length)
      },
      async detectElementsChangeFromPM(productModel) {
        this.productModelToBeChanged = productModel

        if (this.hasElementsToDetectChanges) {
          const data = await this.detectElementsChange(productModel)

          if (!!data.modifiedOptions.length || !!data.modifiedComponents.length) {
            this.isProductModelSelectedDialogShown = true
            this.modifiedElements = {
              options: data.modifiedOptions,
              components: data.modifiedComponents,
              completeOptions: data.options,
              completeComponents: data.components,
              pmIdentifier: productModel?.businessName
            }

            if (productModel.productModelType === PM_TYPE.USER_EXPORTS) {
              this.modifiedElements = {
                ...this.modifiedElements,
                pmIdentifier: !productModel.onDemandExportMetadata
                  ? productModel.displayName : productModel.onDemandExportMetadata.exportName
              }
            }
          }
          else {
            this.updateProductModelWithDetectedChanges({ detectElementsData: data })
          }
        }
        else {
          this.changeProductModel(this.productModelToBeChanged)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-details {
    min-height: 320px;
    background-color: $mi-silver-50;
  }
</style>
